export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT Farming"])},
        "necoFishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
        "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
        "stakeCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stake Requirement"])},
        "lockPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock Period"])},
        "yourStakeAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Staked"])},
        "yourUnlockTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock Time"])},
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no farming pool."])},
        "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAYS"])},
        "stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stake & Lock"])},
        "unstake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim & Mint"])},
        "do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
        "inputAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["input amount"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
        "availableToStake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Amount you are willing to stake"])},
        "availableToUnStake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Amount you are willing to unstake"])},
        "withdrawHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can withdraw what you staked any moment"])}
      },
      "zh": {
        "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT农场"])},
        "necoFishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neco Fishing"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
        "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行中"])},
        "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
        "stakeCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质押要求"])},
        "lockPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["锁仓时间段"])},
        "yourStakeAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的质押数量"])},
        "yourUnlockTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的解锁时间"])},
        "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无挖矿池子."])},
        "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
        "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授权"])},
        "stake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["质押 & 锁仓"])},
        "unstake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赎回 & 铸造"])},
        "do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "inputAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入数量"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总量"])},
        "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大"])},
        "availableToStake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入要质押的数量"])},
        "availableToUnStake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入要提取的数量"])},
        "withdrawHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在任何时候都可以将你的质押赎回"])}
      }
    }
  })
}
