import { Contract } from 'web3-eth-contract';
import Web3 from 'web3';
import store from '@/store';

import { GetterCommands } from '@/store/getters';
import NftFarming from '@/common/contracts/abis/nft-farming';

export function getFarmingContract(address: string): Contract | null {
  const web3 = store.getters[GetterCommands.GET_WEB3];
  if (!web3) {
    return null;
  }
  return new web3.eth.Contract(NftFarming.ABI, address);
}

export async function getFarmingPeriod(address: string): Promise<string> {
  const contract = getFarmingContract(address);
  if (contract === null) {
    return '0';
  }
  return contract.methods.farmingPeriod().call();
}

export async function stakedStatusForAccount(address: string, account: string): Promise<boolean> {
  const contract = getFarmingContract(address);
  if (contract === null) {
    return false;
  }
  return contract.methods.stakedStatusForAccount(account).call();
}

// 获得当前用户的质押数量
export async function getStakedAmountOfAccount(
  address: string, account: string,
): Promise<string> {
  const contract = getFarmingContract(address);
  if (contract === null) {
    return '0';
  }
  return contract.methods.stakedAmountForAccount(account).call();
}

// unlock time
export async function unlockTimeForAccount(address: string, account: string): Promise<number> {
  const contract = getFarmingContract(address);
  if (contract === null) {
    return 0;
  }
  return contract.methods.unlockTimeForAccount(account).call();
}

// Stake Required Amount
export async function tokenRequiredAmount(address: string): Promise<string> {
  const contract = getFarmingContract(address);
  if (contract === null) {
    return '0';
  }
  return contract.methods.tokenRequiredAmount().call();
}

// 质押代币
export async function stakeToken(
  address: string,
  from: string,
): Promise<boolean> {
  const contract = getFarmingContract(address);
  if (contract === null) {
    return false;
  }

  try {
    await contract.methods.stake().send({ from });
    return true;
  } catch (error) {
    return false;
  }
}

// 提取我的质押币
export async function withdraw(
  address: string,
  account: string,
): Promise<boolean> {
  const contract = getFarmingContract(address);
  if (contract === null) {
    return false;
  }
  try {
    await contract.methods.withdraw().send({ from: account });
    return true;
  } catch (error) {
    return false;
  }
}
