enum FarmStatus {
  opening = 'Opening',
  close = 'Close',
}

export default FarmStatus;

export interface StakeDialogDataType {
  title: string;
  desc: string;
  availableAmount: string;
  max: string;
}
