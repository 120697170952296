/* eslint-disable global-require */
import FarmPoolType from '@/configs/farming/farm-pool-type';
import FarmStatus from '@/configs/farming/nft-farm/nft-farm-types';
import { currentEnvironment, ProjectEnvironment } from '@/common/project-environment';

class NFTFarmPool {
  // 编号
  public index: number;

  // Farming的标题
  public title: string;

  // 质押挖矿池子的类型
  public farmPoolType: FarmPoolType;

  public status: FarmStatus = FarmStatus.opening;

  public stakeTitle: string;

  public stakeDesc: string;

  public stakeTokenImg: string;

  public stakeCondition = '0';

  public lockPeriod = '0';

  public yourStakeAmount = '0';

  public yourUnlockTime = '0';

  public link: string;

  public stakedTokenAddress: string;

  // 质押挖矿池子的地址
  public poolAddress: string;

  public nftId: string;

  public constructor(
    index: number,
    title: string,
    farmPoolType: FarmPoolType,
    status: FarmStatus,
    stakeTitle: string,
    stakeDesc: string,
    stakeTokenImg: string,
    link: string,
    stakedTokenAddress: string,
    poolAddress: string,
    nftId: string,
  ) {
    this.index = index;
    this.title = title;
    this.farmPoolType = farmPoolType;
    this.status = status;
    this.stakeTitle = stakeTitle;
    this.stakeDesc = stakeDesc;
    this.stakeTokenImg = stakeTokenImg;
    this.link = link;
    this.stakedTokenAddress = stakedTokenAddress;
    this.poolAddress = poolAddress;
    this.nftId = nftId;
  }
}

/* eslint-disable */
const alligator = require('../../../assets/images/farms/nft-farm/tools/alligator.png');
const nfish = require('../../../assets/images/farms/nft-farm/NFISH.png');
const neco = require('../../../assets/images/farms/nft-farm/NECO.png');
/* eslint-disable */

const progressNFTFarmPoolsProd: Array<NFTFarmPool> = [
  new NFTFarmPool(
    1,
    'Stake NECO earn NFT',
    FarmPoolType.NFTFarming,
    FarmStatus.opening,
    'NECO -> NFT',
    'Bamboo Fishing Rod',
    neco,
    'https://docs.necofun.com/games/neco-fishing/in-game-nfts',
    '0xd23891FC1A515A88C571064637502e3766819e2d',
    '0x1a0B63be6308d749d87C793EC6C173D990d94F5E',
    '10001',
  ),
  new NFTFarmPool(
    2,
    'Stake NECO earn NFT',
    FarmPoolType.NFTFarming,
    FarmStatus.opening,
    'NECO -> NFT',
    'Wooden Fishing Rod',
    neco,
    'https://docs.necofun.com/games/neco-fishing/in-game-nfts',
    '0xd23891FC1A515A88C571064637502e3766819e2d',
    '0x643141A66b0e0964095dc97F408Ac0b241D2800F',
    '10002',
  ),
  new NFTFarmPool(
    3,
    'Stake NECO earn NFT',
    FarmPoolType.NFTFarming,
    FarmStatus.opening,
    'NECO -> NFT',
    'Fiberglass Fishing Rod',
    neco,
    'https://docs.necofun.com/games/neco-fishing/in-game-nfts',
    '0xd23891FC1A515A88C571064637502e3766819e2d',
    '0x29FA7c0B139A8762f0b2DeaB578cB63E3842Dc9D',
    '10003',
  ),
  new NFTFarmPool(
    4,
    'Stake NECO earn NFT',
    FarmPoolType.NFTFarming,
    FarmStatus.opening,
    'NECO -> NFT',
    'Carbon Fiber Fishing Rod',
    neco,
    'https://docs.necofun.com/games/neco-fishing/in-game-nfts',
    '0xd23891FC1A515A88C571064637502e3766819e2d',
    '0x4E0A9C8135Fe962F25A2e88E0282BBfA58a2688C',
    '10004',
  ),
  new NFTFarmPool(
    5,
    'Stake NECO earn NFT',
    FarmPoolType.NFTFarming,
    FarmStatus.opening,
    'NECO -> NFT',
    'Plastic Fish Trap',
    neco,
    'https://docs.necofun.com/games/neco-fishing/in-game-nfts',
    '0xd23891FC1A515A88C571064637502e3766819e2d',
    '0x595cCcdb56B8eb8438cA00E08b10f563dE0002e3',
    '11001',
  ),
  new NFTFarmPool(
    6,
    'Stake NECO earn NFT',
    FarmPoolType.NFTFarming,
    FarmStatus.opening,
    'NECO -> NFT',
    'Bamboo Fish Trap',
    neco,
    'https://docs.necofun.com/games/neco-fishing/in-game-nfts',
    '0xd23891FC1A515A88C571064637502e3766819e2d',
    '0xE97C2A49009AbA274EF0b1122A42F2fD8A7df47a',
    '11002',
  ),
  new NFTFarmPool(
    7,
    'Stake NECO earn NFT',
    FarmPoolType.NFTFarming,
    FarmStatus.opening,
    'NECO -> NFT',
    'Nylon Fish Trap',
    neco,
    'https://docs.necofun.com/games/neco-fishing/in-game-nfts',
    '0xd23891FC1A515A88C571064637502e3766819e2d',
    '0xbFc37466ff63D320d3d2A0cB8e6a5Fa3629B907a',
    '11003',
  ),
  new NFTFarmPool(
    8,
    'Stake NECO earn NFT',
    FarmPoolType.NFTFarming,
    FarmStatus.opening,
    'NECO -> NFT',
    'Wire Fish Trap',
    neco,
    'https://docs.necofun.com/games/neco-fishing/in-game-nfts',
    '0xd23891FC1A515A88C571064637502e3766819e2d',
    '0xaabC093389fdaeC11aA86580B9B73DC8efb9F438',
    '11004',
  ),
];

const progressNFTFarmPoolsDev = [
  new NFTFarmPool(
    1,
    'Stake NECO Earn NFT',
    FarmPoolType.NFTFarming,
    FarmStatus.opening,
    'NECO -> NFT',
    'Bamboo Fishing Rod',
    neco,
    'https://docs.necofun.com/games/neco-fishing/in-game-nfts',
    '0xafa98d54481a9ae468ab21b9268609ff50795795',
    '0x31b6E09eD6e5C6AaC6e21BFB1e94014062a60060',
    '10001',
  ),
  new NFTFarmPool(
    2,
    'Stake NECO Earn NFT',
    FarmPoolType.NFTFarming,
    FarmStatus.opening,
    'NECO -> NFT',
    'Wooden Fishing Rod',
    neco,
    'https://docs.necofun.com/games/neco-fishing/in-game-nfts',
    '0xafa98d54481a9ae468ab21b9268609ff50795795',
    '0x2378c35198022ED859CFBfa03bb5BAea289888A1',
    '10002',
  ),
  new NFTFarmPool(
    3,
    'Stake NECO Earn NFT',
    FarmPoolType.NFTFarming,
    FarmStatus.opening,
    'NECO -> NFT',
    'Fiberglass Fishing Rod',
    neco,
    'https://docs.necofun.com/games/neco-fishing/in-game-nfts',
    '0xafa98d54481a9ae468ab21b9268609ff50795795',
    '0x2378c35198022ED859CFBfa03bb5BAea289888A1',
    '10003',
  ),
  new NFTFarmPool(
    4,
    'Stake NECO Earn NFT',
    FarmPoolType.NFTFarming,
    FarmStatus.opening,
    'NECO -> NFT',
    'Carbon Fiber Fishing Rod',
    neco,
    'https://docs.necofun.com/games/neco-fishing/in-game-nfts',
    '0xafa98d54481a9ae468ab21b9268609ff50795795',
    '0x2378c35198022ED859CFBfa03bb5BAea289888A1',
    '10004',
  ),
];

const progressNFTFarmPools = currentEnvironment() === ProjectEnvironment.Production
  ? progressNFTFarmPoolsProd : progressNFTFarmPoolsDev;

const doneFarmPools: Array<NFTFarmPool> = [];

export {
  NFTFarmPool,
  progressNFTFarmPools,
  doneFarmPools,
};
