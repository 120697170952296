import { AbiItem } from 'web3-utils';

const ABI: AbiItem[] = [
  {
    inputs: [
      {
        internalType: 'contract IERC20',
        name: '_stakeToken',
        type: 'address',
      },
      {
        internalType: 'contract INecoNFT',
        name: '_necoNFT',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [

    ],
    name: 'earnedNFTId',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'farmingPeriod',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'id',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_id',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_tokenRequiredAmount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_earnedNFTId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_farmingPeriod',
        type: 'uint256',
      },
    ],
    name: 'initInfo',
    outputs: [

    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'lock',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'lockStaking',
    outputs: [

    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'necoNFT',
    outputs: [
      {
        internalType: 'contract INecoNFT',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'renounceOwnership',
    outputs: [

    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'stake',
    outputs: [

    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'stakeToken',
    outputs: [
      {
        internalType: 'contract IERC20',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
    ],
    name: 'stakedAccountAt',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'stakedAccountLength',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'stakedAmountForAccount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'stakedStatusForAccount',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'tokenRequiredAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [

    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'unlockStaking',
    outputs: [

    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'unlockTimeForAccount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [

    ],
    name: 'withdraw',
    outputs: [

    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export default {
  ABI,
};
